import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]

  return (
    <section className="svgTriangulobottonBg">

      <div className="pic-wrapper w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-center items-center pt-48 md:pt-10">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[35px] md:text-[60px]">
              {title}
            </h1>
            <p className="text-white text-center">
              {texts}
            </p>
          </div>

          <div className="w-full flex justify-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>
          <div className="flex justify-center">
              <img src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsello2%20google.png?alt=media&token=2d90f365-dfbe-487d-ac76-8805ef3aff81" alt="google_certification" className="w-[300px]" />
            </div>

        </div>
      </div>


    </section>

  )
}

export default HeroSection;
